import { sendTriggerUpsellEvent } from "../shared/events";
import sharedCookies from "../shared/sharedCookies";

class _Controller {
  constructor($limits, $tokenManager, $window, $resourceManager, $f, UserService, Modal, $location) {
    this.limits = $limits;
    this.$window = $window;
    this.$location = $location;
    this.tokenManager = $tokenManager;
    this.resourceManager = $resourceManager;
    this.$f = $f;
    this.user = UserService.user;
    this.Modal = Modal;
    this.dictionary = {
      domain: 'reports',
      search: 'searches'
    };
    this.isNotProduction = (typeof __IS_PRODUCTION === 'undefined' || (typeof __IS_PRODUCTION !== 'undefined' && !__IS_PRODUCTION));
  }

  purchase() {
    if (sharedCookies.get('coupon')) {
      return this.$window.location.href = `${__ZUUL_URL}/sign-up`
    }
    sendTriggerUpsellEvent();
  }

  resetSearches(block) {
    this.$f.gen().then(fingerprint => {
      this.resourceManager.request('tokens', 'reset', {_f: fingerprint, block: block}).then(tokenResponse => {
        this.$window.location.reload();
      });
    });
  }

}

_Controller.$inject = ['$limits', '$tokenManager', '$window', '$resourceManager', '$f', 'UserService', 'Modal', '$location'];

export const freeUpsellBanner = {
  controller: _Controller,
  bindings: {
    context: '@',
  },
  template: `
    <div class='free-upsell-banner' ng-hide='$ctrl.user.isZuulUser'>
      <div class='banner-content'>
        <span ng-if='$ctrl.context != "ranking"'>{{$ctrl.tokenManager.remaining + 1}} free {{ $ctrl.dictionary[$ctrl.context] }} remaining. </span>
        <span ng-if='$ctrl.context == "ranking"'>Subscribe for up to 10 ranking lists. </span>
        <a ng-click='$ctrl.purchase()'>Unlock now...</a>
        <a id='reset-searches-button' ng-if='$ctrl.isNotProduction' ng-click='$ctrl.resetSearches()'>Reset limits</a>
      </div>
    </div>`
};
