import './AnnualUpgrade.scss';

import React, { useState, useEffect } from 'react'
import Modal from '../../features/keywords/react/Modal/Modal';
import sharedCookies from '../../shared/sharedCookies';
import { getAnnualForTier, getMonthlyForTier, getPlanByPriceId } from '../../shared/plans.js';
import { switchSubscription } from './backend.js';
import classNames from 'classnames';

export default function AnnualUpgrade({user, countryCode}) {
  
  const [Shown, SetShown] = useState(false);
  const [Stage, SetStage] = useState('one');
  const [StageVisible, SetStageVisible] = useState('one');
  const [OldPrice, SetOldPrice] = useState(null);
  const [NewPrice, SetNewPrice] = useState(null);
  const [Processing, SetProcessing] = useState(false);

  useEffect(() => {
    // const seenBefore = sharedCookies.get('shownAnnualUpgrade');
    const seenBefore = false;
    const { subscription } = user;

    if (seenBefore || !subscription) return;
    if (subscription.state != 'active') return;
    if (subscription.period != 'monthly') return;

    let oldPrice;
    try {
      oldPrice = getPlanByPriceId(subscription.plan_code);
      if (oldPrice.is_trial_plan) oldPrice = getMonthlyForTier(subscription.tier);
    } catch(e) {
      return; // Price is a legacy price so don't show the offer.
    }

    const newPrice = getAnnualForTier(subscription.tier);

    newPrice.amount_per_month = (newPrice.unit_amount_in_cents / 12 / 100).toFixed(2);
    newPrice.amount_per_year = (newPrice.unit_amount_in_cents / 100).toFixed(0);
    newPrice.saving = (oldPrice.unit_amount_in_cents / 100 * 12 - newPrice.unit_amount_in_cents / 100).toFixed(0);
    (oldPrice.unit_amount_in_cents / 100 * 12 - newPrice.unit_amount_in_cents / 100).toFixed(0);
    oldPrice.amount_per_month = (oldPrice.unit_amount_in_cents / 100).toFixed(0);
    oldPrice.amount_per_year = (oldPrice.unit_amount_in_cents / 100 * 12).toFixed(0);
    newPrice.saving_per_cent =  ((1 - newPrice.amount_per_year / oldPrice.amount_per_year) * 100).toFixed(0);
    SetOldPrice(oldPrice);
    SetNewPrice(newPrice);

    setTimeout(() => {
      sharedCookies.set('shownAnnualUpgrade', 'true');
      openModal();
    }, 0);
  }, []);

  const openModal = () => {
    SetShown(true);
  }

  const closeModal = () => {
    SetShown(false);
  }

  const goToStage = (stage) => {
    SetStageVisible('');
    setTimeout(() => {
      SetStage(stage);
      SetStageVisible(stage);
    }, 500);
  }

  const upgrade = () => {
    SetProcessing(true);
    switchSubscription({plan_code: NewPrice.id})
      .then(_response => {
        goToStage('done');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(console.error)
      .finally(() => {
        SetProcessing(false);
      });
  }

  return (
    <Modal show={Shown} dismissable={false} onClose={closeModal}>
      {NewPrice && <div id='annual-upgrade' style={{padding: '0 20px'}}>
        {Stage === 'one' && <div className={classNames({stage: true, visible: StageVisible === 'one'})}>
          <p style={{margin: '30px 0 0'}}>
            <strong><em>
              <span style={{background: 'var(--color-amber)'}}>Warning!</span> You won't see this offer again...
            </em></strong>
          </p>
          <p style={{padding: '0 80px', margin: '30px 0'}}>Special Offer: Sign up for 12 months of Wordtracker and save ${NewPrice.saving}...</p>
          <p style={{}}>
            You're currently on the {OldPrice.tier.toUpperCase()} <strong>monthly</strong> subscription.<br/>
            For one time only we'll offer you <strong>a full year of {NewPrice.tier.toUpperCase()}</strong> (worth ${OldPrice.amount_per_year}) for just</p>
          <p style={{margin: '30px 0 0'}}>
            <span style={{textDecoration: 'line-through', fontSize: '40px', color: 'var(--color-silver)', display: 'inline-block', marginRight: '10px'}}>${OldPrice.amount_per_year}</span>
            <span style={{fontSize: '50px'}}><strong>${NewPrice.amount_per_year}</strong></span>
          </p>

          <div style={{display: 'flex', justifyContent: 'space-around', margin: '30px 0 30px'}}>
            <button style={{width: '180px', fontFamily: 'Proxima Nova Bold'}} className='accented-standard' onClick={() => goToStage('two')}>I'm interested...</button>
            <button style={{width: '180px'}} className='standard' onClick={closeModal}>No thanks</button>
          </div>
          <p style={{color: 'var(--color-silver)'}}>
            <em>(That's a huge saving of {NewPrice.saving_per_cent}%)</em>
          </p>
        </div>}
        {Stage === 'two' && <div className={classNames({stage: true, visible: StageVisible === 'two'})}>
          <p style={{margin: '30px 0 0'}}>
            <strong><em>
              <span style={{background: 'var(--color-amber)'}}>Warning!</span> You won't see this offer again...
            </em></strong>
          </p>
          <p style={{padding: '0 30px'}}>
            This is your one chance to secure a whole year of Wordtracker at a huge discount, ensuring you get maximum value and unbeatable savings.
          </p>
          <p style={{margin: '30px 0 0'}}>
            <span style={{textDecoration: 'line-through', fontSize: '40px', color: 'var(--color-silver)', display: 'inline-block', marginRight: '10px'}}>${OldPrice.amount_per_year}</span>
            <span style={{fontSize: '50px'}}><strong>${NewPrice.amount_per_year}</strong></span>
          </p>

          <div style={{margin: '30px 0 10px'}}>
            <button disabled={Processing} style={{width: '300px', fontFamily: 'Proxima Nova Bold'}} className='accented-standard' onClick={upgrade}>{Processing ? 'Processing...' : `Upgrade now for just ${NewPrice.amount_per_year}`}</button>
            <button style={{width: '180px', border: 0, marginTop: '15px'}} className='standard' onClick={closeModal}>I'm not interested</button>
          </div>
        </div>}
        {Stage === 'done' && <div style={{paddingBottom: '40px'}} className={classNames({stage: true, done: true, 'signup-form-area': true, visible: StageVisible === 'done'})}>
          <div className='big-tick'></div>
          <h2>Upgrade complete!</h2>
          Thank you. One moment while we refresh your account status...
        </div>}
      </div>}
    </Modal>
  )
}
