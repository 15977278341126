import { sendTriggerUpsellEvent } from "../../shared/events";
import "./upsells.scss";

export const $upsells = function($tokenManager, $resourceManager, $window, $searchManager, $timeout, $notifier, UserService, Modal, $q, $limits) {

  var types = {
    gsc_data: {
      purchase: {
        title: "<strong><span class='gsc-logo'>Unlock Google Search Console data with our free trial</strong>",
        html: "<p>For Google Search Console data in Wordtracker, <strong>sign up for a 7-day free trial:</strong></p><ul class='ticks'><li>Unlimited searches</li><li>2000+ keywords</li><li>Unlimited lists and exports</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionFnSecondary: "purchase",
        actionText: "Sign up now...",
        doNotSkipToSignUpModal: true
      },
    },
    unlimited_searches: {
      getMoreSearches: {
        title: "<strong>Sign up for a free trial to keep searching!</strong>",
        html: "<p>All our plans come with a 7-day free trial.</p><ul class='ticks'><li>Unlimited searches</li><li>Unlimited domain reports</li><li>2000+ keywords</li><li>Unlimited lists and exports</li><li>Multiple countries</li><li>SERP previews</li>",
        actionFn: "purchase",
        actionText: "Choose a plan",
        // actionFnSecondary: "getMoreSearches",
        // actionTextSecondary: "Can I just get a few more searches?",
        doNotSkipToSignUpModal: true
      },
      purchase: {
        title: "<strong>Take the free trial and get unlimited searches</strong>.",
        html: "<p>For unlimited searches, <strong>sign up for a free trial:</strong></p><ul class='ticks'><li>Unlimited searches</li><li>2000+ keywords</li><li>Unlimited lists and exports</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionFnSecondary: "purchase",
        actionText: "Sign up now...",
        doNotSkipToSignUpModal: true
      },
      renew: {
        title: "<strong>Renew your subscription</strong> to get unlimited searcheds.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>2000+ keywords</li><li>Unlimited lists and exports</li><li>Multiple countries</li><li>Google Search Console data</li>",
        actionFn: "renew",
        actionText: "Renew now...",
        doNotSkipToSignUpModal: true
      }
    },
    really_unlimited_searches: {
      endTrial: {
        title: "<strong>That's a lot of searches!</strong>",
        html: "<p>Our free trial gives you 50 searches per day. If you like, you can end your trial immediately and get unlimited searches.</p>",
        actionFn: "confirmEndTrial",
        actionText: "End trial now..."
      }
    },
    months: {
      purchase: {
        title: "Take the free trial and get <strong>multiple months</strong>.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>Unlimited lists and exports</li><li>2000+ keywords</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now..."
      },
      upgrade: {
        title: "<strong>Upgrade your subscription</strong> to access more months.",
        html: "Get <strong>more months</strong> when you upgrade.",
        actionFn: "upgrade",
        actionText: "Upgrade now"
      }
    },
    countries: {
      purchase: {
        title: "Take the free trial and get <strong>multiple countries</strong>.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>Unlimited lists and exports</li><li>2000+ keywords</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now..."
      },
      upgrade: {
        title: "<strong>Upgrade your subscription</strong> to access more countries.",
        html: "",
        actionFn: "upgrade",
        actionText: "Upgrade now",
        doNotSkipToSignUpModal: true
      }
    },
    export: {
      purchase: {
        title: "Subscribe to Wordtracker for <strong>unlimited exports</strong>.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>2000+ suggestions</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now..."
      },
      endTrial: {
        title: "End your trial to get <strong>unlimited exports</strong>",
        html: "<p>Click below to end your trial now. Your card will be billed immediately and you'll instantly unlock unlimited CSV exports.</p>",
        actionFn: "confirmEndTrial",
        actionText: "End trial now..."
      }
    },
    ranking: {
      endTrial: {
        title: "End your trial to create <strong>ranking lists</strong>",
        html: "<p>Click below to end your trial now. Your card will be billed immediately and you'll instantly unlock ranking lists.</p>",
        actionFn: "confirmEndTrial",
        actionText: "End trial now..."
      }
    },
    bulk_search: {
      purchase: {
        title: "Subscribe to Wordtracker for <strong>bulk search</strong>.",
        html: "<p>Get results for up to 1000 seeds at once.</p><ul class='ticks'><li>Unlimited searches</li><li>2000+ suggestions</li><li>Multiple countries and months</li><li>Unlimited exports</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now..."
      },
      upgrade: {
        title: "Reactivate your subscription for <strong>bulk search</strong>.",
        html: "<p>Get results for up to 1000 seeds at once.</p><ul class='ticks'><li>Unlimited searches</li><li>2000+ suggestions</li><li>Multiple countries and months</li><li>Unlimited exports</li></ul>",
        actionFn: "upgrade",
        actionText: "Reactivate now..."
      },
      endTrial: {
        title: "End your trial to unlock <strong>bulk search</strong>",
        html: "<p>Bulk search isn't available with our free trial. If you like, you can end your trial now and access bulk search.",
        actionFn: "confirmEndTrial",
        actionText: "End trial now..."
      }
      
    },
    lists: {
      purchase: {
        title: "Take the free trial and get <strong>unlimited lists</strong>.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>2000+ keywords</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now..."
      },
    },
    keywords: {
      purchase: {
        title: "Take the free trial and get <strong>2000+ keywords</strong>.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>Unlimited lists and exports</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now...",
        doNotSkipToSignUpModal: true
      },
    },
    competitors: {
      purchase: {
        title: "Take the free trial and get <strong>up to 50 competitor domains</strong>.",
        html: "<ul class='ticks'><li>Unlimited searches</li><li>Unlimited lists and exports</li><li>Multiple countries</li><li>Google Search Console data</li></ul>",
        actionFn: "purchase",
        actionText: "Sign up now...",
        doNotSkipToSignUpModal: true
      },
    }
  };

  let upsells = {
    active: {},
    actionFns: {
      confirmEndTrial: () => {
        return $q((resolve, reject) => {
          upsells.active.processing = true;
          $resourceManager.request('current', 'end_trial').then(res => {
            $timeout(() => {
              if (res.status == 201) {
                $notifier.green("All done! One moment...", true);
                $timeout(() => {
                  $window.location.reload();
                }, 6000);
                resolve();
              } else {
                upsells.active.processing = false;
                $notifier.red("Something went wrong.");
                reject();
              }
            }, 1000);
          });
        })
      },
      purchase: () => {
        if (UserService.user.isExpiredUser) {
          $window.location.href = `${__ZUUL_URL}/account/subscription`;
        } else {
          $window.location.href = `${__ZUUL_URL}/sign-up`;
        }
      },
      upgrade: () => {
        $window.location.href = `${__ZUUL_URL}/account/subscription`;
      },
      renew: () => {
        $window.location.href = `${__ZUUL_URL}/account/subscription`;
      },
      getMoreSearches: () => {
        $tokenManager.getMore();
        $searchManager.search();
      }
    }
  };

  upsells.get = (type) => {
    var actions = ["getMoreSearches", "endTrial", "purchase", "upgrade", "renew"];
    var action = actions.find(action => {
      return (UserService.user.can(action) && types[type].hasOwnProperty(action));
    });
    let upsell = types[type][action];
    upsell.name = type;
    return upsell;
  };

  upsells.newModal = type => {
    let upsell = upsells.get(type);
    upsells.active = upsell;
    return Modal.new({
      component: 'upsellModal',
      dismissable: true,
    })
  }

  upsells.new = (type, payload) => {
    if (!UserService.user.isFreeUser) {
      if (
        ['export', 'bulk_search', 'really_unlimited_searches'].includes(type) || // If a disabled feature...
        (type === 'ranking' && $limits.raw.non_trial_limits && $limits.raw.non_trial_limits.ranking_lists > $limits.raw.ranking_lists) // ...or ranking lists are being limited by trial..
      ) {
        return upsells.newModal(type); // ...then use this service.
      }
    }
    return sendTriggerUpsellEvent(payload); // For everything else, use the new React modal
  };

  return upsells;

};

$upsells.$inject = ['$tokenManager', '$resourceManager', '$window', '$searchManager', '$timeout', '$notifier', 'UserService', 'Modal', '$q', '$limits'];