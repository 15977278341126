import "./domainBug.scss";
import sharedCookies from "../../shared/sharedCookies";

class _Controller {

  constructor($timeout) {
    this.$timeout = $timeout;
    this.showBugText = false;
  }

  $onInit() {
    if (!sharedCookies.get('seen_domain_bug')) {
      this.$timeout(() => this.openDomainBug(), 3000);
    }
  }

  openDomainBug() {
    this.showBugText = true;
    sharedCookies.set('seen_domain_bug', true);
  }

  closeDomainBug() {
    this.showBugText = false;
  }

}

_Controller.$inject = ['$timeout'];

export const domainBug = {
  controller: _Controller,
  template: require('./domainBug.html')
}
