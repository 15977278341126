import { sendTriggerUpsellEvent } from '../shared/events';
import './upgradeNowBanner.scss';
import sharedCookies from '../shared/sharedCookies';

class _Controller {
  
  COOKIE_KEY = 'closed_upgrade_now_banner';

  constructor(UserService) {

    this.user = UserService.user;

    this.shown = (
      this.user.current1dollarTrial &&
      !this.user.hasUpcomingSub &&
      !sharedCookies.get(this.COOKIE_KEY)
    )

  }

  upgrade(e) {
    e.preventDefault();
    sendTriggerUpsellEvent();
  }

  close() {
    this.shown = false;
    sharedCookies.set(this.COOKIE_KEY, true)
  }

}

_Controller.$inject = ['UserService'];

export const upgradeNowBanner = {
  controller: _Controller,
  template: `
<div ng-if='$ctrl.shown' id="upgrade-now-banner">
  <p>Enjoying your $1 dollar trial? <a ng-click='$ctrl.upgrade($event)'>Purchase a subscription</a> to keep using Wordtracker once your trial is up.</p>
  <button ng-click="$ctrl.close()" class="dismiss-standard">&times;</button>
</div>`
}