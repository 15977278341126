
const decode = string => {
  string = decodeURIComponent(string);
  try {
    return JSON.parse(string);
  } catch(e) {
    return string;
  }
}

export default {

  set: (cookieName, value, daysToExpiry = 7, domain = '.wordtracker.com') => {
    
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpiry * 24 * 60 * 60 * 1000)); // Calculate the expiry date

    value = (typeof value === 'object') ? JSON.stringify(value) : value;

    const params = {
      [cookieName]: value,
      expires: date.toUTCString(),
      domain
    }

    const cookieString = Object.entries(params)
      .map(([key, value]) => key + '=' + value)
      .join(';')

    document.cookie = cookieString;
  },

  get: (cookieName) => {
    const cookie = document.cookie
      .split(";")
      .map(cookie => cookie.trim().split("="))
      .find(([name]) => name === cookieName);

    if (!cookie) return null;
    const [, value] = cookie;
    return decode(value);
  }

};